import * as React from "react";
import { useCallback } from "react";
import HeaderMainNav from "../components/header-main-nav";
import WhatDoesDaveBringWithHim from "../components/what-does-dave-bring-with-him";
import SpeakingEngagementComingUp from "../components/speaking-engagement-coming-up";
import Podcasts from "../components/podcasts";
import Footer from "../components/footer";
import "./keynote-speaking.css";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet-async";

/* https://forecast.weather.gov/meteograms/Plotter.php?lat=47.7618&lon=-122.3015&wfo=SEW&zcode=WAZ558&gset=20&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=11011111111110000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=7
 */ const KeynoteSpeaking = () => {
  const onSpotifyListenBadge1ImageClick = useCallback(() => {
    window.open(
      "https://open.spotify.com/show/2cpZoILWiO5oiwIRpzdpYg?si=b9df6881dfe04bc8"
    );
  }, []);

  const onENGooglePodcastsBadgeClick = useCallback(() => {
    window.open(
      "https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS56ZW5jYXN0LmZtL3RlYWNoLW1lLWJlZXMvcnNz="
    );
  }, []);

  const onSpotifyListenBadge1Image1Click = useCallback(() => {
    window.open("https://open.spotify.com/show/1iY5bKIvTQCPvWQAwWyURs");
  }, []);

  const onENGooglePodcastsBadge1Click = useCallback(() => {
    window.open(
      "https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS56ZW5jYXN0LmZtL3RlYWNoLW1lLWJlZXMvcnNz="
    );
  }, []);

  const onTEJLogoFrameClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='headerMainNav']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPortfolioContainerClick = useCallback(() => {
    navigate("/portfolio");
  }, []);

  const onPodcastsContainerClick = useCallback(() => {
    navigate("/podcasts");
  }, []);

  const onBlogContainerClick = useCallback(() => {
    window.open("https://blog.davewescott.com");
  }, []);

  return (
    <>
      <Helmet>
        <title>Keynote Speaking | DaveWescott.com</title>{" "}
      </Helmet>
      <div className="keynote-speaking">
        <HeaderMainNav
          scrollToPodcasts={onPodcastsContainerClick}
          scrollToPortfolio={onPortfolioContainerClick}
        />

        <section className="one-audience-at-a-time">
          <div className="f-r-a-m-e">
            <iframe
              src="https://www.youtube.com/embed/kU1DqMOF-K8?modestbranding=1&fs=0&controls=0"
              width="560"
              height="315"
              style={{ maxWidth: "90vw" }}
              title="Mission, Vision, and Values | Breakfast Jamboree at #SIMA2023"
              frameborder="0"
            ></iframe>
          </div>
          <div className="navigation1">
            <h1 className="inspiring-change-one">
              Inspiring Change, One Audience at a Time.
            </h1>
            <div className="with-over-20">
              With over 20 years of experience inspiring growth and development
              in organizations globally, Dave Wescott has spent his career
              shaping leaders and empowering teams. As your keynote speaker, his
              expertise and insights will drive your business forward.
            </div>
            <div className="from-entrepreneurial-leadershi">
              From entrepreneurial leadership to conscious capitalism, Dave
              Wescott offers a range of keynote topics curated to cater to your
              specific objectives, sector, and requirements.
            </div>
          </div>
        </section>
        <WhatDoesDaveBringWithHim />
        <SpeakingEngagementComingUp />
        <Podcasts
          onSpotifyListenBadge1ImageClick={onSpotifyListenBadge1ImageClick}
          onENGooglePodcastsBadgeClick={onENGooglePodcastsBadgeClick}
          onSpotifyListenBadge1Image1Click={onSpotifyListenBadge1Image1Click}
          onENGooglePodcastsBadge1Click={onENGooglePodcastsBadge1Click}
        />
        <Footer
          onFooterLogoClick={onTEJLogoFrameClick}
          onPortfolioContainerClick={onPortfolioContainerClick}
          onPodcastsContainerClick={onPodcastsContainerClick}
          onBlogContainerClick={onBlogContainerClick}
        />
      </div>
    </>
  );
};

export default KeynoteSpeaking;
